import { useContext, useState } from "react";

import DashboardContext from "../DashboardContext";
import { AI_Page } from "./layouts/AI_Page";
import { AI_Settings } from "./layouts/AI_Settings";
import { useTranslation } from "react-i18next";

export const AI_Tanja = ({ mode }) => {
  const { setSide } = useContext(DashboardContext);
  const { t } = useTranslation();
  const [opportunities, setOpportunities] = useState([
    {
      text: t("manageMyKnowlage"),
      func: () => setSide("file-uploader"),
    },
    {
      text: t("chatWithMe"),
      func: () => setSide("chat"),
    },
    {
      text: t("generalSettings"),
      func: () => setSide("ai-settings"),
    },
  ]);

  const [settings, setSettings] = useState([
    {
      text: "Support Email",
      func: () => {},
    },
    {
      text: "Company aimes",
      func: () => {},
    },
  ]);

  return mode === "page" ? (
    <AI_Page opportunities={opportunities} name="Tanja" />
  ) : (
    <AI_Settings settings={settings} name="Tanja" />
  );
};
