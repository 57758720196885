import { signOut } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import LandingPage from "./landing-page/LandingPage";
import Dashboard from "./dashboard/Dashboard";
import { isTokenValid, refreshToken } from "./tokenUtils";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChaBotApp from "./ChatBot/ChatBotApp";
import ShopifyApp from "./ChatBot/ShopifyApp";

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scopes: [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN],
          redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");

  const handleLogout = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      setIsLoggedIn(false);
      setUserName("");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleLogin = () => {
    //signInWithRedirect();
    window.location.href =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_SIGNIN_URL_PROD
        : process.env.REACT_APP_SIGNIN_URL;
  };

  const check_session = async () => {
    // Check existing session
    const id_token = sessionStorage.getItem("idToken");

    if (await isTokenValid()) {
      try {
        // Decode ID token using jwt-decode
        const decodedIdToken = jwtDecode(id_token);

        // Assuming the username is stored under "cognito:username"
        // This attribute could be different based on your Cognito configuration
        if (decodedIdToken && decodedIdToken["cognito:username"]) {
          setUserName(decodedIdToken["cognito:username"]);
          setIsLoggedIn(true);
          return;
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }

    if (await refreshToken()) {
      setIsLoggedIn(true);
      return;
    }

    // Parse the URL to get the authorization code
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // Exchange the authorization code for tokens
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const redirectUri = encodeURIComponent(
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_REDIRECT_SIGN_IN_PROD
          : process.env.REACT_APP_REDIRECT_SIGN_IN
      );
      const url = `https://${process.env.REACT_APP_DOMAIN}/oauth2/token`;

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      const body = `grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectUri}&code=${code}`;

      fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Tokens:", data);
          if (data.access_token) {
            // Store tokens
            sessionStorage.setItem("accessToken", data.access_token);
            sessionStorage.setItem("idToken", data.id_token);
            sessionStorage.setItem("refreshToken", data.refresh_token);
            // Optionally store refresh token and ID token
            // sessionStorage.setItem('refreshToken', data.refresh_token);
            // sessionStorage.setItem('idToken', data.id_token);
            const decodedToken = jwtDecode(data.id_token);

            // Assuming the username is stored under "cognito:username"
            // This attribute could be different based on your Cognito configuration
            if (decodedToken && decodedToken["cognito:username"]) {
              setUserName(decodedToken["cognito:username"]);
              sessionStorage.setItem(
                "user_name",
                decodedToken["cognito:username"]
              );
              setIsLoggedIn(true);
            }

            // Set logged in to true if we receive an access token
          }
        })
        .catch((error) => {
          console.error("Error exchanging authorization code:", error);
        });
    }
  };

  useEffect(() => {
    check_session();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/chat" element={<ChaBotApp />} />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              handleLogout={handleLogout}
              isLoggedIn={isLoggedIn}
              userName={userName}
            />
          }
        />
        <Route path="/shopChat" element={<ShopifyApp />} />
        <Route
          path="/"
          element={
            <>
              {isLoggedIn ? (
                <Dashboard
                  handleLogout={handleLogout}
                  isLoggedIn={isLoggedIn}
                  userName={userName}
                />
              ) : (
                <LandingPage
                  handleLogin={handleLogin}
                  handleLogout={handleLogout}
                  isLoggedIn={isLoggedIn}
                  userName={userName}
                />
              )}
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
