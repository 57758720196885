import { Fragment, useState } from "react";
import { Avatar } from "@mui/material";
import styled from "styled-components";

import { AI_Page } from "./layouts/AI_Page";
import { AI_Settings } from "./layouts/AI_Settings";

const AIAvatar = styled(Avatar)`
  border-radius: 0.25em;
  width: 150px;
  height: 150px;
`;

const AIName = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const AI_Jessi = ({ mode }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [settings, setSettings] = useState([]);

  return mode === "page" ? (
    <AI_Page opportunities={opportunities} name="Jessi" />
  ) : (
    <AI_Settings settings={settings} name="Jessi" />
  );
};
