import { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MainListItems from "./listItems";
import ObjectList from "./ObjectList";
import FileUploader from "./FileUploader";
import Chart from "./Chart";
import Deposits from "./Deposits";
import { Logout } from "@mui/icons-material";
import { FileProvider } from "./FileContext";
import DashboardContext from "./DashboardContext"; // Import the context
import UserSettings from "./UserSettings";
import ChatBot from "../ChatBot/ChatBot";
import ChatHistory from "./ChatHistoryViewer";
import AI_Manager from "./AI_Manager";
import AI_Cluster from "./AI_Cluster";

import CustomerFeedbackViewer from "./CustomerFeedbackViewer";
import ErrorBoundary from "./components/ErrorBoundary";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({ handleLogout, userName }) {
  const [open, setOpen] = useState(false);
  const [side, setSide] = useState("dashboard");
  const [activeBot, setActiveBot] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <DashboardContext.Provider
      value={{ side, setSide, activeBot, setActiveBot }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Dashboard
              </Typography>
              <Typography>{userName}</Typography>
              <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <IconButton color="inherit">
                <Logout onClick={handleLogout} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <MainListItems />
              {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              {side === "dashboard" ? (
                <Container>
                  <AI_Manager />
                </Container>
              ) : side === "users" ? (
                <Container>
                  <Typography
                    variant="h5"
                    component="h6"
                    align="left"
                    style={{ wordWrap: "break-word" }}
                  >
                    Manage acces right to other users
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Chart />
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Deposits />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              ) : side === "settings" ? (
                <Container>
                  <UserSettings />
                </Container>
              ) : side === "ai" ? (
                <Container>
                  <AI_Cluster mode="page" />
                </Container>
              ) : side === "history" ? (
                <Container>
                  <ChatHistory />
                </Container>
              ) : side === "feedbacks" ? (
                <Container>
                  <CustomerFeedbackViewer />
                </Container>
              ) : side === "chat" ? (
                <Container>
                  <ChatBot />
                </Container>
              ) : side === "file-uploader" ? (
                <Container>
                  <FileProvider>
                    <FileUploader />
                    <ObjectList />
                  </FileProvider>
                </Container>
              ) : side === "ai-settings" ? (
                <Container>
                  <AI_Cluster mode="settings" />
                </Container>
              ) : (
                ""
              )}

              {/* Recent Deposits 
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid>
              {/* Recent Orders 
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Orders />
                </Paper>
              </Grid>*/}
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </DashboardContext.Provider>
  );
}
