import { Rating, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import LoadingOverlay from "./LoadingOverlay";

import ConversationModal from "./CustomerConversationModal";

import "animate.css/animate.css";

const CustomerFeedbacksWrapper = styled.div``;

const CustomerFeedbacksInner = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
  }
`;

const CustomerFeedbackHead = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
  }
`;

const CustomerFeedbacksSortBtns = styled.div`
  display: flex;
  gap: 7.5px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

const CustomerFeedbacksSortBtn = styled(Button)`
  display: block;
`;

const CustomerFeedbacksTitle = styled(Typography)`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const CustomerFeedbacksItem = styled.div`
  position: relative;
  width: 300px;
  padding: 25px 5px;
  border: 1px solid grey;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const CustomerFeedbackRatingItem = styled.div``;

const CustomerFeedbackRating = styled(Rating)`
  font-size: 20px;
`;

const CustomerFeedbacksDate = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 12px;
  font-weight: bold;
`;

const CustomerConversationBtn = styled.button`
  cursor: pointer;
  margin-left: auto;
  border: none;
  background-color: transparent;
  color: grey;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const urlPath = `${process.env.REACT_APP_CUSTOMERS_FEEDBACKS_API}${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2getCustomerFeedbacks"
    : "/int/int2getCustomerFeedbacks"
}`;

const CustomerFeedbackViewer = () => {
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState(data);
  const [activeSort, setActiveSort] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  const [isConversationModal, setIsConversationModal] = useState(false);
  const [conversatioSession, setConversationSession] = useState(null);

  useEffect(() => {
    onFetchFeedbacks();
  }, []);

  useEffect(() => {
    onSortedData();
  }, [activeSort]);

  const onSortedData = () => {
    let sortedDataCopy = [...sortedData];

    switch (activeSort) {
      case "date":
        sortedDataCopy.sort((a, b) => {
          return (
            new Date(b.datestamp).getTime() - new Date(a.datestamp).getTime()
          );
        });
        break;
      case "rating":
        sortedDataCopy.sort((a, b) => {
          let sumA = a.solveProblem + a.useAgain + a.overallExperience;
          let sumB = b.solveProblem + b.useAgain + a.overallExperience;

          if (sumA < sumB) {
            return 1;
          } else if (sumA > sumB) {
            return -1;
          } else {
            return 0;
          }
        });
        break;
      default:
        sortedDataCopy = [...data];
        break;
    }

    setSortedData(sortedDataCopy);
  };

  const { t } = useTranslation();

  const onFetchFeedbacks = () => {
    fetchFeedbacks()
      .then(transformData)
      .then((data) => {
        setData(data);
        setSortedData(data);
        setIsLoading(false);
        setIsDataReady(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchFeedbacks = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(urlPath, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw Error("Something went wrong");
        }

        const responseData = await response.json();
        resolve(responseData);
      } catch (error) {
        reject(error);
      }
    });
  };

  const transformData = (data) => {
    return data.map(
      ({
        sessionId: { S: sessionId },
        problem_solved: { S: problem_solved },
        use_again: { S: use_again },
        overall_experience: { S: overall_experience },
        datestamp: { S: datestamp },
      }) => {
        return {
          sessionId,
          problemSolved: problem_solved,
          useAgain: use_again,
          overallExperience: overall_experience,
          datestamp,
        };
      }
    );
  };

  const closeConversationModal = () => {
    setIsConversationModal(false);
    setConversationSession(null);
  };

  const renderFeedbacks = () => {
    return (
      isDataReady &&
      sortedData.map(
        ({
          sessionId,
          problemSolved,
          useAgain,
          overallExperience,
          datestamp,
        }) => {
          return (
            <CustomerFeedbacksItem
              className="animate__animated animate__fadeIn animate__faster"
              key={uuidv4()}
            >
              <div>
                <CustomerFeedbackRatingItem>
                  <Typography>{t("hasYourProblemSolved")}</Typography>
                  <CustomerFeedbackRating value={problemSolved} readOnly />
                </CustomerFeedbackRatingItem>

                <CustomerFeedbackRatingItem>
                  <Typography>{t("useAgainChat")}</Typography>
                  <CustomerFeedbackRating value={useAgain} readOnly />
                </CustomerFeedbackRatingItem>

                <CustomerFeedbackRatingItem>
                  <Typography>{t("overallExperience")}</Typography>
                  <CustomerFeedbackRating value={overallExperience} readOnly />
                </CustomerFeedbackRatingItem>
              </div>
              <CustomerFeedbacksDate>
                {format(datestamp, "yyyy/MM/dd HH:mm")}
              </CustomerFeedbacksDate>

              <CustomerConversationBtn
                onClick={() => {
                  setIsConversationModal(true);
                  setConversationSession(sessionId);
                }}
              >
                {t("toConversation")}
              </CustomerConversationBtn>
            </CustomerFeedbacksItem>
          );
        }
      )
    );
  };

  return (
    <CustomerFeedbacksWrapper>
      <LoadingOverlay isLoading={isLoading} />
      <CustomerFeedbackHead>
        <CustomerFeedbacksTitle
          style={{
            marginRight: "auto",
            color: "#1976d2",
          }}
        >
          {t("feedbacks")}
        </CustomerFeedbacksTitle>
        <CustomerFeedbacksTitle style={{ color: "#1976d2", fontSize: "20px" }}>
          {t("sortBy")}:
        </CustomerFeedbacksTitle>
        <CustomerFeedbacksSortBtns>
          <CustomerFeedbacksSortBtn
            onClick={() => setActiveSort("rating")}
            variant={activeSort === "rating" ? "contained" : "outlined"}
          >
            {t("rating")}
          </CustomerFeedbacksSortBtn>
          <CustomerFeedbacksSortBtn
            onClick={() => setActiveSort("date")}
            variant={activeSort === "date" ? "contained" : "outlined"}
          >
            {t("date")}
          </CustomerFeedbacksSortBtn>
          <CustomerFeedbacksSortBtn
            onClick={() => setActiveSort(null)}
            variant="outlined"
          >
            {t("reset")}
          </CustomerFeedbacksSortBtn>
        </CustomerFeedbacksSortBtns>
      </CustomerFeedbackHead>

      <CustomerFeedbacksInner>{renderFeedbacks()}</CustomerFeedbacksInner>

      {conversatioSession && (
        <ConversationModal
          sessionId={conversatioSession}
          open={isConversationModal}
          onClose={closeConversationModal}
        />
      )}
    </CustomerFeedbacksWrapper>
  );
};

export default CustomerFeedbackViewer;
