import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";

export default function LandingPage({ handleLogin, handleLogout }) {
  const [mode, setMode] = React.useState("light");
  // const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  React.useEffect(() => {
    const chatButton = document.getElementById('chatButton');
    const chatIframe = document.getElementById('chatIframe');
    const closeButton = document.getElementById('closeButton');

    // Load the iframe source URL from a configuration file or generate it dynamically
    const iframeSourceUrl = 'https://ai.squaretime.de/chat/?client_id=df08827a32bf8567f538f44ef6bc40ebaa66e00d4519595943d0a3088cb78913';

    let iframeLoaded = false;

    const handleChatButtonClick = () => {
      // Toggle display
      if (chatIframe.style.display === 'none') {
        chatIframe.style.display = 'block';
        // Load the iframe only when the chat is opened for the first time
        if (!iframeLoaded) {
          const iframe = document.createElement('iframe');
          iframe.src = iframeSourceUrl;
          iframe.frameBorder = 0;
          iframe.style.width = '90vw';
          iframe.style.height = '70vh';
          iframe.style.maxWidth = '500px';
          chatIframe.appendChild(iframe);
          iframeLoaded = true;
        }
      } else {
        chatIframe.style.display = 'none';
      }
    };

    const handleCloseButtonClick = () => {
      chatIframe.style.display = 'none';
    };

    chatButton.addEventListener('click', handleChatButtonClick);
    closeButton.addEventListener('click', handleCloseButtonClick);

    // Cleanup function
    return () => {
      chatButton.removeEventListener('click', handleChatButtonClick);
      closeButton.removeEventListener('click', handleCloseButtonClick);
    };
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar
        mode={mode}
        toggleColorMode={toggleColorMode}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
      <Hero />

      {/* <Box sx={{ bgcolor: "background.default" }}>
        <LogoCollection />
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box> */}
      {/* Chat button and iframe */}
      {/* Add the CSS */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      <style>
        {`
          /* Styles for mobile devices */
          @media (max-width: 767px) {
            #chatButton {
              bottom: 10px;
              right: 10px;
            }

            #chatButton img {
              height: 60px;
            }

            #chatIframe {
              bottom: 60px;
              right: 10px;
            }

            #chatIframe iframe {
              width: 90vw;
              height: 60vh;
              max-width: 400px;
              max-height: 500px;
            }
          }

          #chatButton {
            position: fixed;
            bottom: 200px;
            right: 0px;
            z-index: 1000;
          }

          .chatimage {
            cursor: pointer;
            height: 70px;
            border: none;
          }

          #chatIframe {
            display: none;
            position: fixed;
            bottom: 70px;
            right: 10px;
            z-index: 1000;
          }

          #closeButton {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            font-size: 24px;
            background-color: #f2f2f2;
            padding: 5px;
            border-radius: 50%;
          }

          #chatHeading {
            position: absolute;
            top: 1rem;
            left: 5rem;
            cursor: pointer;
          }
        `}
      </style>
      <div id="chatButton">
        <img
          src="https://ai.squaretime.de/chat.png"
          alt="Open Chat"
          style={{ cursor: 'pointer', height: '70px', border: 'none' }}
        />
      </div>
      <div id="chatIframe" style={{ display: 'none' }}>
        <span id="closeButton" className="material-symbols-outlined">
          close
        </span>
      </div>
    </ThemeProvider>
  );
}
