import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import LoadingOverlay from "./LoadingOverlay";
import CustomerChat from "./components/CustomerChat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "1em",
  boxShadow: 24,
  maxHeight: "75vh",
  overflow: "auto",
  pt: 2,
  px: 4,
  pb: 3,

  "@media (max-width: 768px)": {
    width: "80%",
    pt: 1,
    px: 1,
    pb: 1,
  },
};

const urlPath = `${process.env.REACT_APP_CUSTOMER_CHAT_HISTORY_API}${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2getChatHistory"
    : "/int/int2getChatHistory"
}`;

const ConversationModal = ({ sessionId, open, onClose }) => {
  const [dataConversation, setDataConversation] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onFetchConversation();
  }, []);

  const transformData = (data) => {
    return data.map((elem) => {
      return {
        clientId: elem.client_id.S,
        lastUpdated: elem.lastUpdated.S,
        messages: elem.messages_without_context.L,
        sessionId: elem.sessionId.S,
        username: elem.username.S,
      };
    });
  };

  const onFetchConversation = () => {
    fetchCustomerConversation()
      .then(transformData)
      .then((data) => {
        setIsLoading(false);
        setDataConversation(...data);
        setIsDataReady(true);
      })
      .catch((error) => {
        setIsLoading(false);
        onClose();
        console.log(error);
      });
  };

  const fetchCustomerConversation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(`${urlPath}?sessionId=${sessionId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });

        if (!response.ok) {
          throw Error("Something went wrong");
        }

        const responseData = await response.json();
        resolve(responseData);
      } catch (error) {
        reject(error);
      }
    });
  };

  return isLoading ? (
    <LoadingOverlay isLoading={isLoading} />
  ) : (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        {isDataReady && (
          <CustomerChat
            sessionId={dataConversation.sessionId}
            messages={dataConversation.messages}
            lastUpdated={dataConversation.lastUpdated}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ConversationModal;
