import React, { createContext, useContext, useState } from 'react';

const FileContext = createContext();

export const useFileContext = () => useContext(FileContext);

export const FileProvider = ({ children }) => {
  const [refreshFiles, setRefreshFiles] = useState(false);

  const triggerRefresh = () => {
    setRefreshFiles(!refreshFiles);
  };

  return (
    <FileContext.Provider value={{ refreshFiles, triggerRefresh }}>
      {children}
    </FileContext.Provider>
  );
};