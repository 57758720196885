const logErrorUrl = `https://qubnc7alyk.execute-api.eu-central-1.amazonaws.com${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2logBrowserError"
    : "/int/int2logBrowserError"
}`;

export async function transmitError(errorData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(logErrorUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(errorData),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const data = response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
