import React, { Component } from "react";
import StackTrace from "stacktrace-js";

const urlPath = `https://qubnc7alyk.execute-api.eu-central-1.amazonaws.com${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2logBrowserError"
    : "/int/int2logBrowserError"
}`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    this.logErrorToServer(error, errorInfo);
  }

  async logErrorToServer(error, errorInfo) {
    const stackFrames = await StackTrace.fromError(error);
    const errorData = {
      sessionId: this.props.sessionId,
      clientId: this.props.clientId,
      message: error.message,
      stacks: stackFrames,
      datestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
    };

    this.onFetchError(errorData)
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  }

  onFetchError(errorData) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(urlPath, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(errorData),
        });

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const data = response.json();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  render() {
    if (this.state.hasError) {
      this.props.redirect();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
