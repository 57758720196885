import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import AICard from "./components/AICard";
import DashboardContext from "./DashboardContext";
import { Portal } from "../landing-page/components/Portal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "./LoadingOverlay";

const AI_ManageItems = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
`;

const ChatFeedbackAlert = styled(Alert)`
  width: max-content;
  margin: 0 auto;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px 0;
`;

const urlPath = `${process.env.REACT_APP_API_URL}${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2getUserAttributes"
    : "/int/int2getUserAttributes"
}`;

const AI_Manager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { activeBot, setActiveBot } = useContext(DashboardContext);
  const [accessesBots, setAccessesBots] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isAccessForbbiden, setIsAccessForbbiden] = useState(false);

  const [emailValue, setEmailValue] = useState("");
  const [supportCaseValue, setSupportCaseValue] = useState("");

  const { t } = useTranslation();
  const { setSide } = useContext(DashboardContext);

  useEffect(() => {
    setIsLoading(true);
    fetchAccessToBot()
      .then(transormData)
      .then((data) => {
        setIsLoading(false);
        setIsDataReady(true);
        setAccessesBots(data.accessesBots);
        setEmailValue(data.email);
        setSupportCaseValue(data.support_reason);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    return () => {
      setIsAccessForbbiden(false);
    };
  }, []);

  const transormData = ({ userAttributes }) => {
    return {
      accessesBots: userAttributes.purchased_products.L.map((elem) =>
        elem.S.toLowerCase()
      ),
      email: userAttributes.email.S || "",
      support_reason: userAttributes.support_reason?.S || "",
    };
  };

  const checkAccessBot = (botName) => {
    return accessesBots.includes(botName);
  };

  const onChangeBot = (botName) => {
    setIsAccessForbbiden(false);

    if (checkAccessBot(botName)) {
      handleSubmit().then((_) => {
        setActiveBot(botName);
        setSide("ai");
      });
    } else {
      setIsAccessForbbiden(true);
    }
  };

  const fetchAccessToBot = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(urlPath, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/int/int2changeUserAttributes",
          {
            method: "POST",
            headers: {
              Authorization: `${token}`, // Include the token in the Authorization header
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: emailValue,
              support_reason: supportCaseValue,
            }),
          }
        );
        const data = await response.json();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <Typography
      variant="h5"
      component="h6"
      align="left"
      style={{ wordWrap: "break-word" }}
    >
      {t("manageYourAIemployees")}
      {isDataReady && (
        <AI_ManageItems>
          <AICard
            botName={"Tanja"}
            onChangeBot={() => onChangeBot("tanja")}
            isActive={activeBot === "tanja"}
            disabled={!isDataReady}
            isAvailable={checkAccessBot("tanja")}
          />
          <AICard
            botName={"Jessi"}
            onChangeBot={() => onChangeBot("jessi")}
            isActive={activeBot === "jessi"}
            disabled={!isDataReady}
            isAvailable={checkAccessBot("jessi")}
          />
          <AICard
            botName={"Sven"}
            onChangeBot={() => onChangeBot("sven")}
            isActive={activeBot === "sven"}
            disabled={!isDataReady}
            isAvailable={checkAccessBot("sven")}
          />
        </AI_ManageItems>
      )}
      {isAccessForbbiden && (
        <Portal>
          <ChatFeedbackAlert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            severity="error"
            className="animate__animated animate__fadeIn animate__faster"
          >
            Access Forbbiden
          </ChatFeedbackAlert>
        </Portal>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Typography>
  );
};

export default AI_Manager;
