import { Button, Rating } from "@mui/material";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Portal } from "../landing-page/components/Portal";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "animate.css/animate.css";

const ChatFeedbackModal = styled.div`
  margin: 25px auto;
  border: 2px solid rgba(0, 0, 0, 0.3);
  max-width: 30em;
  border-radius: 2px;
  padding: 5px;
`;

const ChatFeedbackItem = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
`;

const ChatFeedbackText = styled.p`
  font-size: 14px;
`;

const ChatFeedbackRating = styled(Rating)`
  display: flex;
  align-items: center;
`;

const ChatFeedbackConfirmBtn = styled(Button)`
  margin-top: 20px;
  display: block;
  margin-left: auto;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  background-color: #007bff;
  letter-spacing: 0.02;

  &:hover {
    opacity: 0.8;
  }
`;

const ChatFeedbackAlert = styled(Alert)`
  width: max-content;
  margin: 0 auto;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
`;

const urlPath =
  process.env.NODE_ENV === "production"
    ? "/prod/prod2sendFeedback"
    : "/int/int2sendFeedback";

const ChatFeedback = ({
  onCloseModal,
  onDisabledFeedback,
  sessionId,
  clientId,
}) => {
  const [problemSolvedFeedback, setProblemSolvedFeedback] = useState(0);
  const [useAgainFeedback, setUseAgainFeedback] = useState(0);
  const [overallExperienceFeedback, setExperienceFeedback] = useState(0);

  const [isSuccessFeedback, setIsSuccessFeedback] = useState(false);
  const [isErrorFeedback, setIsErrorFeedback] = useState(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);

  const { t } = useTranslation();

  const closeTimer = useRef();

  const onCloseAlert = () => {
    setIsFeedbackLoading(false);

    closeTimer.current = setTimeout(() => {
      setIsSuccessFeedback(false);
      setIsErrorFeedback(false);
      onCloseModal();
    }, 1500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(closeTimer.current);
    };
  }, []);

  const onSubmitFeedback = (data) => {
    setIsFeedbackLoading(true);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CUSTUMER_FEEDBACK}${urlPath}`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const responseData = await response.json();
        resolve(responseData);
      } catch (error) {
        console.error("Error:", error);
        reject(error);
      }
    });
  };

  const prepareFeedBack = () => {
    return {
      sessionId,
      client_id: clientId,
      datestamp: new Date(),
      problem_solved: problemSolvedFeedback.toString(),
      use_again: useAgainFeedback.toString(),
      overall_experience: overallExperienceFeedback.toString(),
    };
  };

  const onSendFeedback = () => {
    onSubmitFeedback(prepareFeedBack())
      .then(() => {
        setIsSuccessFeedback(true);
        onDisabledFeedback();
      })
      .catch((err) => setIsErrorFeedback(true))
      .finally(onCloseAlert);
  };

  return (
    <ChatFeedbackModal className="animate__animated animate__fadeIn">
      <ChatFeedbackItem>
        <ChatFeedbackText>{t("hasYourProblemSolved")}</ChatFeedbackText>
        <ChatFeedbackRating
          value={problemSolvedFeedback}
          onChange={(_, value) => setProblemSolvedFeedback(value)}
        />
      </ChatFeedbackItem>

      <ChatFeedbackItem>
        <ChatFeedbackText>{t("useAgainChat")}</ChatFeedbackText>
        <ChatFeedbackRating
          value={useAgainFeedback}
          onChange={(_, value) => setUseAgainFeedback(value)}
        />
      </ChatFeedbackItem>

      <ChatFeedbackItem>
        <ChatFeedbackText>{t("overallExperience")}</ChatFeedbackText>
        <ChatFeedbackRating
          value={overallExperienceFeedback}
          onChange={(_, value) => setExperienceFeedback(value)}
        />
      </ChatFeedbackItem>

      <ChatFeedbackConfirmBtn
        onClick={onSendFeedback}
        disabled={isFeedbackLoading}
      >
        {t("send")}
      </ChatFeedbackConfirmBtn>

      {isSuccessFeedback && (
        <Portal>
          <ChatFeedbackAlert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            className="animate__animated animate__fadeIn animate__faster"
          >
            {t("feedBackSuccess")}
          </ChatFeedbackAlert>
        </Portal>
      )}

      {isErrorFeedback && (
        <Portal>
          <ChatFeedbackAlert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            severity="error"
            className="animate__animated animate__fadeIn animate__faster"
          >
            {t("somethingWentWrong")}
          </ChatFeedbackAlert>
        </Portal>
      )}
    </ChatFeedbackModal>
  );
};

export default ChatFeedback;
