import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

const Chat = styled.div`
  margin-bottom: 50px;
  background-color: white;
  border-radius: 1em;
  padding: 15px;
`;

const ChatHead = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  gap: 0 5px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 5px 0;
  }
`;

const ChatDate = styled.div`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const ChatSession = styled.div`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const ChatRole = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const ChatMessage = styled.div`
  font-size: 12px;
`;

const ChatMessageInner = styled.div`
  padding: 10px 15px;
`;

const CustomerChat = ({ sessionId, messages, lastUpdated }) => {
  const { t } = useTranslation();

  const renderMessages = (messages) => {
    return messages.map(({ M: { content, role } }) => {
      if (role.S === "system") return;

      return (
        <div key={uuidv4()}>
          <ChatRole>{role.S === "user" ? "customer" : role.S}:</ChatRole>
          <ChatMessage>
            <ChatMessageInner>{content.S}</ChatMessageInner>
          </ChatMessage>
        </div>
      );
    });
  };

  return (
    <Chat>
      <ChatHead>
        <ChatSession>SessionId: {sessionId}</ChatSession>
        <ChatDate>
          {t("date")}: {format(lastUpdated, "yyyy-MM-dd HH:mm")}
        </ChatDate>
      </ChatHead>

      {renderMessages(messages)}
    </Chat>
  );
};

export default CustomerChat;
