import React, { useState } from "react";
import styled from "styled-components";
import { Drawer } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ChatBot from "./ChatBot";

// Chat component (assuming you have already imported the necessary dependencies)

const ChatIconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const ChatIconButton = styled.button`
  background-color: #0959aa;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const ChatDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 400px;
    max-width: 100vw;
    height: 600px;
    max-height: 100vh;
    background-color: #f5f5f5;

    @media (max-width: 480px) {
      width: 100vw;
      height: 100vh;
    }
  }
`;

const ShopifyApp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // eslint-disable-next-line
  const [mode, setMode] = React.useState("light");
  const defaultTheme = createTheme({ palette: { mode } });
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <>
        <ChatIconContainer>
          <ChatIconButton onClick={toggleDrawer}>
            <ChatIcon />
          </ChatIconButton>
        </ChatIconContainer>
        <ChatDrawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          <ChatBot />
        </ChatDrawer>
      </>
    </ThemeProvider>
  );
};

export default ShopifyApp;
