import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: require("./locales/en.json"),
    fr: require("./locales/fr.json"),
    es: require("./locales/es.json"),
    de: require("./locales/de.json"),
    ua: require("./locales/ua.json"),
  },
  lng: navigator.language.split("-")[0], // Set the initial language based on the browser's language
  fallbackLng: "en", // Fallback language if the user's language is not supported
  interpolation: { escapeValue: false },
});

export default i18n;
