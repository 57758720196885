// LoadingOverlay.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: 'absolute', // Absolute positioning to make it an overlay
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingOverlay;