import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import { Container, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFileContext } from "./FileContext";
import { isTokenValid, refreshToken } from "../tokenUtils";
import LoadingOverlay from "./LoadingOverlay";
import { format } from "date-fns";
import styled from "styled-components";

const FileListItem = styled(ListItem)`
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    border-left: 1px solid grey;
    display: block;
  }
`;

const FileListItemInner = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 767px) {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

const FileListItemButtonDelete = styled(IconButton)`
  @media (max-width: 767px) {
    order: 1;
  }
`;

const FileListItemDate = styled(Typography)`
  @media (max-width: 767px) {
    order: 2;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const FileListItemText = styled.span`
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const FileListItemDownload = styled(ListItemIcon)`
  @media (max-width: 767px) {
    min-width: auto;
  }
`;

const ObjectList = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { refreshFiles } = useFileContext();

  const fetchPresignedUrls = async () => {
    try {
      if (!(await isTokenValid())) {
        await refreshToken();
      }
      setIsLoading(true);
      const token = sessionStorage.getItem("accessToken");
      const url = `${process.env.REACT_APP_API_URL}${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2listS3Objects"
    : "/int/int2listS3Objects"
}`;


      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Failed to fetch pre-signed URLs");
      }

      const data = await response.json();
      setIsLoading(false);
      setItems(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching pre-signed URLs:", error.message);
    }
  };

  const deleteFile = async (filename) => {
    try {
      setIsLoading(true);
      if (!isTokenValid()) {
        await refreshToken();
      }
      const token = sessionStorage.getItem("accessToken");
      const url = `${process.env.REACT_APP_API_URL}${
        process.env.NODE_ENV === "production"
          ? "/prod/prod2deleteObjectFromS3"
          : "/int/int2deleteObjectFromS3"
      }`;
      const body = JSON.stringify({ fileName: filename });

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body,
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Failed to delete file");
      }

      setIsLoading(false);
      fetchPresignedUrls();
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting file:", error.message);
    }
  };

  const handleFileClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    fetchPresignedUrls();
  }, [refreshFiles]);

  return (
    <Container style={{ position: "relative", minHeight: "100px" }}>
      <LoadingOverlay isLoading={isLoading} />
      <List>
        {items.map((item, index) => {
          if (index === 0) return null;
          const filename = item.Key.split("/").pop();
          const lastModified = item.LastModified;

          return (
            <FileListItem key={index}>
              <FileListItemInner>
                <FileListItemDownload>
                  <CloudDownloadIcon
                    onClick={() => handleFileClick(item.PresignedUrl)}
                    style={{ cursor: "pointer" }}
                  />
                </FileListItemDownload>
                <FileListItemText>{filename}</FileListItemText>
              </FileListItemInner>

              <FileListItemInner>
                <FileListItemDate>
                  {format(new Date(lastModified), "yyyy-MM-dd HH:mm")}
                </FileListItemDate>
                <FileListItemButtonDelete
                  onClick={() => deleteFile(filename)}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </FileListItemButtonDelete>
              </FileListItemInner>
            </FileListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default ObjectList;
