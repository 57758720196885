import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ChatBot from "./ChatBot";

export default function ChaBotApp() {
  // eslint-disable-next-line
  const [mode, setMode] = React.useState("light");
  const defaultTheme = createTheme({ palette: { mode } });
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <base target="_blank" />
      <ChatBot />
    </ThemeProvider>
  );
}
