import React, { useState } from "react";
import { Button, Box, Typography, Chip, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BackupIcon from "@mui/icons-material/Backup";
import CircularProgress from "@mui/material/CircularProgress";
import { useFileContext } from "./FileContext";
import { isTokenValid, refreshToken } from "../tokenUtils";
import styled from "styled-components";

const FileUploaderBtnsWrapper = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px 0;
    text-align: center;
  }
`;

const FileUploadBtn = styled(Button)`
  @media (max-width: 480px) {
    margin-left: 0;
  }
`;

const FileUploadComponent = () => {
  const { triggerRefresh } = useFileContext();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const url = `${process.env.REACT_APP_API_URL}${
    process.env.NODE_ENV === "production"
      ? "/prod/prod2uploadS3Object"
      : "/int/int2uploadS3Object"
  }`;

  // Function to sort files by name
  const sortFilesByName = (files) => {
    return files.sort((a, b) => a.name.localeCompare(b.name));
  };
  const MAX_SIZE = 50 * 1024 * 1024; // Example: 5 MB limit

  const handleFileChange = (event) => {
    const filesToAdd = Array.from(event.target.files).filter(
      (file) => file.size <= MAX_SIZE
    );
    setFiles((prevFiles) => sortFilesByName([...prevFiles, ...filesToAdd]));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles((prevFiles) =>
      sortFilesByName([...prevFiles, ...Array.from(event.dataTransfer.files)])
    );
  };

  // Handle file upload
  const handleUpload = async () => {
    if (files.length > 0) {
      setIsLoading(true);
      if (!(await isTokenValid())) {
        await refreshToken();
      }
      try {
        await Promise.all(
          files.map(async (file) => {
            const token = sessionStorage.getItem("accessToken");
            // First step: Obtain pre-signed URL from your backend
            const response = await fetch(url, {
              method: "POST",
              body: JSON.stringify({
                fileName: file.name,
                contentType: file.type, // Send the MIME type of the file
              }),
              headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error(
                "Failed to obtain pre-signed URL for file: " + file.name
              );
            }

            const { uploadUrl } = await response.json();

            // Second step: Use the pre-signed URL to upload the file directly to S3
            const uploadResponse = await fetch(uploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": file.type, // Important: Set the correct content type
              },
              body: file, // Directly upload the file object
            });

            if (!uploadResponse.ok) {
              throw new Error("Upload to S3 failed for file: " + file.name);
            }
          })
        );

        console.log("All files uploaded successfully.");
        // Proceed to clear state and possibly notify the user
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during the file upload.");
      } finally {
        setIsLoading(false);
        setFiles([]);
        triggerRefresh(); // Triggering refresh after completion
      }
    }
  };

  return (
    <Box
      textAlign="center"
      mt={5}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: "2px dashed grey",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h6" color="textSecondary" gutterBottom>
        File Upload
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        mb={2}
      >
        <input
          accept="*/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <FileUploaderBtnsWrapper>
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              sx={{
                bgcolor: "primary.main",
                "&:hover": { bgcolor: "primary.dark" },
              }}
            >
              Select Files
            </Button>
          </label>
          <FileUploadBtn
            variant="contained"
            onClick={handleUpload}
            startIcon={<BackupIcon />}
            sx={{
              bgcolor: "secondary.main",
              color: "white",
              "&:hover": { bgcolor: "secondary.dark" },
              marginLeft: 2, // adjust spacing to match your design
            }}
            disabled={isLoading || files.length === 0} // disable during upload or when no files are selected
          >
            {isLoading ? "Uploading..." : "Upload"}
          </FileUploadBtn>
        </FileUploaderBtnsWrapper>
      </Stack>

      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {files.length > 0 && (
        <Stack direction="column" spacing={1} mt={2}>
          {files.map((file, index) => (
            <Chip
              key={index}
              icon={<CloudUploadIcon />}
              label={file.name}
              variant="outlined"
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default FileUploadComponent;
