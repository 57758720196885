import { importJWK, jwtVerify } from "jose";

export const isTokenValid = async () => {
  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken) {
    try {
      return await verifyTokenSignatureAndValidateClaims(accessToken);
    } catch (error) {
      console.error("Error validating token:", error);
      sessionStorage.removeItem("accessToken");
      return false;
    }
  } else {
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("idToken");
    return false;
  }
};

async function verifyTokenSignatureAndValidateClaims(token) {
  try {
    // eslint-disable-next-line
    const { payload, protectedHeader } = await jwtVerify(
      token,
      async (header) => {
        // Fetch the JSON Web Key Set (JWKS)
        const jwksUri = `https://cognito-idp.eu-central-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}/.well-known/jwks.json`;
        const response = await fetch(jwksUri);
        if (!response.ok) {
          throw new Error(`Error fetching JWKS: ${response.statusText}`);
        }
        const { keys } = await response.json();

        // Find the correct key based on the `kid` in the JWT header
        const signInKey = keys.find((key) => key.kid === header.kid);
        if (!signInKey) {
          throw new Error("Key not found");
        }

        // Correctly import the JWK for verification
        return await importJWK(signInKey, "RS256");
      },
      {
        algorithms: ["RS256"],
      }
    );

    // At this point, the token's signature is verified.
    // You can now proceed to validate the token's claims as per your needs.

    // Example: Check if the token is expired
    if (payload.exp < Date.now() / 1000) {
      return false;
    }

    console.log("Token is valid, payload:", payload);
    return true; // Indicate the token is valid
  } catch (error) {
    console.error("Error verifying token:", error);
    sessionStorage.removeItem("accessToken");
    throw error;
  }
}

export const refreshToken = async () => {
  // Check existing session
  const refreshToken = sessionStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const url = `https://${process.env.REACT_APP_DOMAIN}/oauth2/token`;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error refreshing tokens: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Refreshed tokens:", data);

      // Store tokens
      sessionStorage.setItem("accessToken", data.access_token);
      sessionStorage.setItem("idToken", data.id_token);

      return true;
    } catch (error) {
      console.error("Error refreshing tokens:", error);
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");
      return false;
    }
  }
  return false;
};
