import React, { useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { ReactComponent as UserFrameSvg } from "../assets/images/user-frame.svg";
import { ReactComponent as FeedbacksSvg } from "../assets/images/chats.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatIcon from "@mui/icons-material/Chat";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DashboardContext from "./DashboardContext"; // Correct path as necessary

const menuItems = [
  {
    side_id: "dashboard",
    icon: <DashboardIcon />,
    text: "Dashboard",
    bot: null,
  },
  { side_id: "users", icon: <PeopleIcon />, text: "Users", bot: null },
  { side_id: "settings", icon: <SettingsIcon />, text: "Settings", bot: null },
  {
    side_id: "ai",
    icon: <UserFrameSvg style={{ fill: "inherit" }} />,
    text: "AI",
    bot: "tanja",
  },
  {
    side_id: "file-uploader",
    icon: <AttachFileIcon />,
    text: "Documents",
    bot: "tanja",
  },
  {
    side_id: "chat",
    icon: <ChatIcon />,
    text: "Chat",
    bot: "tanja",
  },
  {
    side_id: "history",
    icon: <AccessTimeIcon />,
    text: "AI",
    text: "History",
    bot: "tanja",
  },
  {
    side_id: "feedbacks",
    icon: <AutoAwesomeIcon />,
    text: "Feedbacks",
    bot: "tanja",
  },
  {
    side_id: "ai-settings",
    icon: <ManageAccountsIcon />,
    text: "AI-Settings",
    bot: "tanja",
  },
  {
    side_id: "ai",
    icon: <UserFrameSvg style={{ fill: "inherit" }} />,
    text: "AI",
    bot: "jessi",
  },
  {
    side_id: "ai-settings",
    icon: <ManageAccountsIcon />,
    text: "AI-Settings",
    bot: "jessi",
  },
];

const MainListItems = () => {
  const { activeBot, side, setSide } = useContext(DashboardContext);

  return (
    <React.Fragment>
      {menuItems.map(({ side_id, icon, text, bot }, index) => {
        if (activeBot === bot || !bot) {
          return (
            <ListItemButton
              key={side_id}
              onClick={() => setSide(side_id)}
              style={{
                borderBottom:
                  index === 2 && activeBot ? "2px solid grey" : "none",
              }}
            >
              <ListItemIcon
                style={{
                  color: side_id === side ? "#1976d2" : "grey",
                  fill: side_id === side ? "#1976d2" : "grey",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={text}
                style={{
                  color: side_id === side ? "#1976d2" : "grey",
                }}
              />
            </ListItemButton>
          );

          return;
        }
      })}
    </React.Fragment>
  );
};

export default MainListItems;
