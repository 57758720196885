import { Avatar } from "@mui/material";
import styled from "styled-components";

const AIAvatar = styled(Avatar)`
  border-radius: 0.25em;
  width: 150px;
  height: 150px;
`;
const ClusterItems = styled.div`
  margin-top: 50px;
  max-width: 550px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

const ClusterBtn = styled.button`
  cursor: pointer;
  text-align: left;
  width: 250px;
  height: 80px;
  padding: 20px;
  background-color: transparent;
  border: none;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  border-radius: 0.2em;
  transition: all 1s;

  &:hover {
    box-shadow: none;
  }
`;

const AIName = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const AI_Page = ({ name, opportunities }) => {
  return (
    <>
      <AIName>{name}</AIName>
      <AIAvatar />

      <ClusterItems>
        {opportunities.map(({ text, func }) => {
          return (
            <ClusterBtn key={text} onClick={func}>
              {text}
            </ClusterBtn>
          );
        })}
      </ClusterItems>
    </>
  );
};
