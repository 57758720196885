import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import SendIcon from "@mui/icons-material/Send";
import { Button, Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useChatMessages from "./hooks/useChatMessages";
import useClientId from "./hooks/useClientId";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import CustomerFeedback from "./CustomerFeedback";

const ChatContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  justify-content: center;
  background-color: #f5f5f5;

  @media (max-width: 767px) {
    height: 100vh;
    padding: 0.5rem;
    flex-direction: column;
  }
`;

const AgentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const AgentAvatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.5rem;

  @media (max-width: 767px) {
    width: 2rem;
    height: 2rem;
  }
`;

const ChatBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 37.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  overflow-y: auto;
  height: calc(100vh - 200px);

  @media (max-width: 767px) {
    max-width: 100%;
    height: calc(100vh - 200px);
    padding: 0.75rem; /* Review this to ensure it's not too little */
    margin: auto; /* Center align the ChatBox for better look */
  }
`;

const Message = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
`;

const BotMessage = styled(Message)`
  justify-content: flex-start;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

const MessageContent = styled.span`
  background-color: ${({ isBot }) => (isBot ? "#e6e6e6" : "#007bff")};
  color: ${({ isBot }) => (isBot ? "#333" : "white")};
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 1rem;
  display: inline-block;
  min-width: 100px;

  @media (max-width: 767px) {
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
  }
`;

const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const typingDots = keyframes`
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
`;

const TypingDots = styled.span`
  font-size: 16px;
  margin-left: 4px;
  &::after {
    content: "";
    animation: ${typingDots} 1s infinite;
  }
`;

const MessageInput = styled.input`
  width: 100%;
  max-width: 37.5rem;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 16px;
  display: inline-block;
  min-width: 100px;
  height: 40px;

  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0.5rem 0.75rem;
  }
`;

const SendButton = styled(Button)`
  margin-left: 8px;
  height: 40px;
  // margin-top: 20px;
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
`;

const ChatForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const UserMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const UserMessageContent = styled.span`
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 70%;
  word-wrap: break-word;
  align-self: flex-end;
`;

const LeaveCommentBtn = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  color: white;
  font-size: 12px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.02;
`;

const MessageContentWithHTML = ({ isBot, messageContent }) => {
  const sanitizedHTML = DOMPurify.sanitize(messageContent);

  return (
    <MessageContent
      isBot={isBot}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  );
};

const ChatBot = () => {
  const [messageInput, setMessageInput] = useState("");
  const messagesEndRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(); // Access the translation function

  const { isLoading, clientId, fetchClientId } = useClientId(searchParams);
  const {
    messages,
    sendMessage,
    typingIndicator,
    sendInitialMessage,
    sessionId,
  } = useChatMessages(clientId);

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (messageInput.trim() !== "") {
        sendMessage(messageInput);
        setMessageInput("");
      }
    },
    [messageInput, sendMessage]
  );

  const [isClientReady, setIsClientReady] = useState(false);
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);
  const [isAllowFeedback, setAllowFeedback] = useState(false);

  useEffect(() => {
    fetchClientId().then(() => {
      setIsClientReady(true);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isClientReady) {
      setAllowFeedback(true);
    }
    // eslint-disable-next-line
  }, [sessionId]);

  useEffect(() => {
    if (isClientReady) {
      sendInitialMessage();
    }
    // eslint-disable-next-line
  }, [isClientReady]);

  useEffect(() => {
    if (messagesEndRef.current) {
      const { scrollHeight, clientHeight } = messagesEndRef.current;
      const maxScrollTop = scrollHeight - clientHeight;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [messages, typingIndicator]);

  return (
    <ChatContainer>
      <LoadingOverlay isLoading={isLoading} />
      <AgentContainer>
        <AgentAvatar src="/static/tanja.webp" alt="Tanja, Customer Support" />
        {/* <BetaBadge>Early Beta</BetaBadge> */}
      </AgentContainer>
      <ChatBox id="chat-box" aria-live="polite" ref={messagesEndRef}>
        {messages.map((message, index) => (
          <>
            {message.sender === "user" ? (
              <UserMessage key={index}>
                <UserMessageContent>
                  {message.messageContent}
                </UserMessageContent>
              </UserMessage>
            ) : (
              <BotMessage key={index}>
                <Avatar src="/tanja.webp" alt="Tanja" />
                <MessageContentWithHTML
                  isBot={message.isBot}
                  messageContent={`Tanja: ${message.messageContent}`}
                />
              </BotMessage>
            )}
          </>
        ))}
        {isFeedbackModal && isClientReady ? (
          <CustomerFeedback
            onCloseModal={() => setIsFeedbackModal(false)}
            onDisabledFeedback={() => setAllowFeedback(false)}
            sessionId={sessionId}
            clientId={clientId}
          />
        ) : null}
        {typingIndicator && (
          <TypingIndicator>
            <BotMessage>
              <Avatar src="/tanja.webp" alt="Tanja" />
              <MessageContent isBot>
                Tanja: <TypingDots />
              </MessageContent>
            </BotMessage>
          </TypingIndicator>
        )}
        {!isFeedbackModal && isAllowFeedback ? (
          <LeaveCommentBtn onClick={() => setIsFeedbackModal(true)}>
            {t("leaveFeedback")}
          </LeaveCommentBtn>
        ) : null}
      </ChatBox>
      <ChatForm id="chat-form" onSubmit={handleSendMessage}>
        <MessageInput
          type="text"
          name="message"
          id="message-input"
          autoComplete="off"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          placeholder={t("placeholderText")} // Use the translation function
        />
        <SendButton
          type="submit"
          id="send-button"
          variant="contained"
          color="primary"
        >
          <SendIcon />
        </SendButton>
      </ChatForm>
    </ChatContainer>
  );
};

export default ChatBot;
