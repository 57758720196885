import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Grid, Container } from "@mui/material";
import { isTokenValid, refreshToken } from "../tokenUtils";
import LoadingOverlay from "./LoadingOverlay";

const UserAttributesForm = () => {
  // Assuming you have a way to get the authenticated user's username
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    client_id: "",
    company_name: "",
    support_reason: "",
    avatar_name: "",
    street: "",
    postal_code: "",
    city: "",
    support_email: "", // Add this line
  });

  // Fetch user data when the component mounts
  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (!(await isTokenValid())) {
        await refreshToken();
      }
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/int/int2getUserAttributes",
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.userAttributes) {
        setFormData({
          email: data.userAttributes.email?.S || "",
          client_id: data.userAttributes.client_id?.S || "",
          company_name: data.userAttributes.company_name?.S || "",
          support_reason: data.userAttributes.support_reason?.S || "",
          avatar_name: data.userAttributes.avatar_name?.S || "",
          street: data.userAttributes.street?.S || "",
          postal_code: data.userAttributes.postal_code?.S || "",
          city: data.userAttributes.city?.S || "",
          support_email: data.userAttributes.support_email?.S || "",
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "email" && name !== "client_id") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (!(await isTokenValid())) {
        await refreshToken();
      }
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/int/int2changeUserAttributes",
        {
          method: "POST",
          headers: {
            Authorization: `${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Send formData as the request body
        }
      );
      const data = await response.json();
      setIsLoading(false);
      alert(data.message); // Display success message
    } catch (error) {
      console.error("Failed to update user attributes:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const readOnlyFieldStyle = {
    backgroundColor: "#f0f0f0",
  };
  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />

      <Typography variant="h5" gutterBottom>
        Update your Settings
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              InputProps={{
                readOnly: true,
                style: readOnlyFieldStyle,
              }}
              helperText="This field cannot be edited"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Client ID"
              name="client_id"
              value={formData.client_id}
              onChange={handleChange}
              InputProps={{
                readOnly: true,
                style: readOnlyFieldStyle,
              }}
              helperText="This field cannot be edited"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="What is your company's aime?"
              name="support_reason"
              value={formData.support_reason}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Avatar Name"
              name="avatar_name"
              value={formData.avatar_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Support Email"
              name="support_email"
              value={formData.support_email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Street"
              name="street"
              value={formData.street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Postal Code"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default UserAttributesForm;
