import { useContext } from "react";
import DashboardContext from "./DashboardContext";

import { AI_Tanja } from "./AI/AI_Tanja";
import { AI_Jessi } from "./AI/AI_Jessi";

const AI_Cluster = ({ mode }) => {
  const { activeBot } = useContext(DashboardContext);

  const renderActiveBot = () => {
    switch (activeBot) {
      case "tanja": {
        return <AI_Tanja mode={mode} />;
      }
      case "jessi": {
        return <AI_Jessi mode={mode} />;
      }
    }
  };

  return renderActiveBot();
};

export default AI_Cluster;
