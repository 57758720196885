// hooks/useTypingIndicator.js
import { useState, useEffect } from 'react';

const useTypingIndicator = () => {
  const [typingIndicator, setTypingIndicator] = useState(false);
  const [typingIndicatorTimeout, setTypingIndicatorTimeout] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(typingIndicatorTimeout);
    };
  }, [typingIndicatorTimeout]);

  const startTypingIndicator = () => {
    setTimeout(() => {
      setTypingIndicator(true);
    }, 4000);
    const timeout = setTimeout(() => {
      setTypingIndicator(false);
    }, 120000); // Adjust the timeout duration as needed
    setTypingIndicatorTimeout(timeout);
  };

  const stopTypingIndicator = () => {
    clearTimeout(typingIndicatorTimeout);
    setTypingIndicator(false);
  };

  return { typingIndicator, startTypingIndicator, stopTypingIndicator };
};

export default useTypingIndicator;