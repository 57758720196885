import { Avatar, Button, Typography } from "@mui/material";
import styled from "styled-components";
import "animate.css/animate.css";

const AICardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  width: 300px;
  min-height: 450px;
  border-radius: 0.25em;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.15);
  transition: all 0.4s;
  border: 1px solid transparent;
`;

const AICardName = styled(Typography)`
  font-size: 24px;
  margin-left: 25px;
  margin-bottom: 15px;
`;

const AICardAvatar = styled(Avatar)`
  margin-left: 25px;
  border-radius: 0.25em;
  width: 75%;
  height: 215px;
`;

const AICardBtn = styled(Button)`
  margin: 0 auto;
  margin-top: auto;
  width: 80%;
  display: block;
`;

const AICardList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
  margin: 10px 0 0 45px;
`;

const AICardListItem = styled.li`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
`;

const AICard = ({ botName, isActive, onChangeBot, disabled, isAvailable }) => {
  return (
    <AICardWrapper
      className=""
      style={{
        ...(isActive
          ? {
              transform: "scale(1.05)",
              borderColor: "#1976d2",
            }
          : {}),
        ...(!isAvailable
          ? {
              pointerEvents: "none",
              opacity: 0.5,
            }
          : {}),
      }}
    >
      <AICardName>{botName}</AICardName>
      <AICardAvatar />
      <AICardList>
        <AICardListItem>Support Employee</AICardListItem>
        <AICardListItem>Customer Managment</AICardListItem>
        <AICardListItem>Sells</AICardListItem>
      </AICardList>
      <AICardBtn variant="contained" onClick={onChangeBot} disabled={disabled}>
        Manage
      </AICardBtn>
    </AICardWrapper>
  );
};

export default AICard;
