// hooks/useClientId.js
import { useState } from "react";
import { isTokenValid, refreshToken } from "../../tokenUtils";

const useClientId = (searchParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientId, setClientId] = useState(null);

  const fetchClientId = async () => {
    try {
      setIsLoading(true);
      if (!(await isTokenValid())) {
        await refreshToken();
      }
      const urlPath = `${process.env.REACT_APP_API_URL}${process.env.NODE_ENV === "production" ? "/prod/prod2getUserAttributes" : "/int/int2getUserAttributes"}`;
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        urlPath,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (data.userAttributes) {
        setClientId(data.userAttributes.client_id?.S || "");
      } else {
        const clientIdFromUrl = searchParams.get("client_id");
        setClientId(clientIdFromUrl);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
    return;
  };

  return { isLoading, clientId, fetchClientId };
};

export default useClientId;
