import styled from "styled-components";
import { Avatar } from "@mui/material";
import { useEffect } from "react";

const AIAvatar = styled(Avatar)`
  border-radius: 0.25em;
  width: 150px;
  height: 150px;
`;
const ClusterItems = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
`;

const ClusterBtn = styled.button`
  cursor: pointer;
  text-align: left;
  width: 200px;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  border: none;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  border-radius: 0.2em;
  transition: all 1s;

  &:hover {
    box-shadow: none;
  }
`;

const AIName = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const AI_Settings = ({ name, settings }) => {
  return (
    <>
      <AIName>{name}</AIName>
      <AIAvatar />

      <>
        <ClusterItems>
          {settings.map(({ text, func }) => {
            return (
              <ClusterBtn key={text} onClick={func}>
                {text}
              </ClusterBtn>
            );
          })}
        </ClusterItems>
      </>
    </>
  );
};
