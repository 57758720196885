import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import useClientId from "../ChatBot/hooks/useClientId";
import LoadingOverlay from "./LoadingOverlay";
import { DateRange } from "react-date-range";
import CustomerChat from "./components/CustomerChat";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ChatHistoryWrapper = styled.div``;

const ChatHistoryContent = styled.div``;

const ChatHistoryItem = styled.div`
  margin-bottom: 50px;
  background-color: white;
  border-radius: 1em;
  padding: 15px;
`;

const ChatHistoryHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
`;

const Calendar = styled(DateRange)`
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const ChatHistoryEmpty = styled(ChatHistoryItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 285px;
`;

const urlPath = `${process.env.REACT_APP_CUSTOMER_CHAT_HISTORY_API}${
  process.env.NODE_ENV === "production"
    ? "/prod/prod2getChatHistory"
    : "/int/int2getChatHistory"
}`;

const ChatHistory = () => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const [isDataReady, setIsDataReady] = useState(false);
  const [isClientReady, setIsClientReady] = useState(false);

  const [searchParams] = useSearchParams();

  const { clientId, fetchClientId } = useClientId(searchParams);

  const [timeRange, setTimeRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { t } = useTranslation();

  useEffect(() => {
    fetchClientId().then(() => {
      setIsClientReady(true);
    });
  }, []);

  useEffect(() => {
    isClientReady && getChatHistory();
  }, [isClientReady]);

  const getChatHistory = (
    startDate = timeRange[0].startDate,
    endDate = timeRange[0].endDate
  ) => {
    setData([]);
    setError("");
    setIsLoading(true);
    setIsDataReady(false);

    isClientReady &&
      fetchChatHistory(startDate, endDate)
        .then(transformData)
        .then(sortByDate)
        .then((data) => {
          setData(data);
          setIsLoading(false);
          setIsDataReady(true);
        })
        .catch(({ message }) => {
          setIsLoading(false);
          setError(message);
        });
  };

  const fetchChatHistory = async (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(
          `${urlPath}?client_id=${clientId}&startDate=${startDate}&endDate=${endDate}`,
          {
            method: "GET",
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error("History is empty");
        }

        const responseData = await response.json();
        resolve(responseData);
      } catch (error) {
        reject(error);
      }
    });
  };

  const sortByDate = (data) => {
    return data.sort((a, b) => {
      return new Date(b.lastUpdated) - new Date(a.lastUpdated);
    });
  };

  const transformData = (data) => {
    return data.map((elem) => {
      return {
        clientId: elem.client_id.S,
        lastUpdated: elem.lastUpdated.S,
        messages: elem.messages_without_context.L,
        sessionId: elem.sessionId.S,
        username: elem.username.S,
      };
    });
  };

  const onChangeDateRange = (item) => {
    setIsLoading(true);
    setTimeRange([item.selection]);

    const startDate = item.selection.startDate;
    const endDate = item.selection.endDate;

    getChatHistory(startDate, endDate);
  };

  return (
    <ChatHistoryWrapper>
      <LoadingOverlay isLoading={isLoading} />
      <ChatHistoryHead>
        <Calendar
          editableDateInputs={true}
          onChange={onChangeDateRange}
          moveRangeOnFirstSelection={false}
          ranges={timeRange}
          disabled={isLoading}
          style={{
            pointerEvents: isLoading ? "none" : "all",
          }}
        />
        <div style={{ flexGrow: 1 }}>
          <h3>{t("history")}</h3>
          {isDataReady && (
            <ChatHistoryContent>
              {data.map(({ sessionId, messages, lastUpdated }) => {
                // Check only system chat
                if (messages.length === 1 && messages[0].M.role.S === "system")
                  return;

                return (
                  <CustomerChat
                    key={uuidv4()}
                    messages={messages}
                    sessionId={sessionId}
                    lastUpdated={lastUpdated}
                  />
                );
              })}
            </ChatHistoryContent>
          )}
          {error.length > 0 && <ChatHistoryEmpty>{error}</ChatHistoryEmpty>}
        </div>
      </ChatHistoryHead>
    </ChatHistoryWrapper>
  );
};

export default ChatHistory;
