import { useState, useRef, useEffect } from "react";
import useTypingIndicator from "./useTypingIndicator";
import { transmitError } from "../../services/transmitErrors";
import { format } from "date-fns";
import StackTrace from "stacktrace-js";

const useChatMessages = (clientId) => {
  const [messages, setMessages] = useState([]);
  const abortControllerRef = useRef(new AbortController());
  const [sessionId, setSessionId] = useState(null);
  const [userLanguage, setUserLanguage] = useState("");

  const { startTypingIndicator, stopTypingIndicator, typingIndicator } = useTypingIndicator();

  useEffect(() => {
    const lang = navigator.language || navigator.userLanguage;
    setUserLanguage(lang);
  }, []);

  const appendMessage = (sender, messageContent, isBot = false, userMessage = "") => {
    const newMessage = {
      sender,
      messageContent,
      isBot,
      userMessage,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendInitialMessage = () => {
    sendMessage(`Hello, my countrycode is ${userLanguage}. Use my language in the conversation`, true);
  };

  const fetchWithRetry = async (url, options, retries = 3, backoff = 300) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Response not OK with status: " + response.status);
      return response.json();
    }
    catch (error) {
      if (retries > 1) {
        setTimeout(() => {
          return fetchWithRetry(url, options, retries - 1, backoff * 2);
        }, backoff);
      } else {
        throw error("Failed after 3 retries");
      }
    }
  };

  const getSessionId = async () => {
    try {
      const urlPath = process.env.NODE_ENV === "production" ? "prod/prod2getSessionId" : "int/int2getSessionId";
      const response = await fetchWithRetry(`https://qubnc7alyk.execute-api.eu-central-1.amazonaws.com/${urlPath}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ client_id: clientId }),
      });

      setSessionId(response.sessionId);
      return response.sessionId;
    } catch (error) {
      const stackFrames = await StackTrace.fromError(error);
      const errorData = {
        message: "getSessionId failed:" + error.message,
        stacks: stackFrames,
        datestamp: format(new Date(), "yyyy-MM-dd HH:mm"),
        userAgent: navigator.userAgent,
      };
      await transmitError(errorData).catch(console.log);
      console.log(error);
      appendMessage("bot", "Sorry, there was an issue getting the session ID. Please try again.");
    }
  };

  const sendMessage = async (userMessage, isHidden = false) => {
    abortControllerRef.current.abort(); // Abort any ongoing requests.
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    if (!isHidden) {
      appendMessage("user", userMessage);
    }
    startTypingIndicator(); // Start the typing indicator

    try {
      const urlPath = process.env.NODE_ENV === "production" ? "prod2chat" : "int2chat";
      const response = await fetchWithRetry(
        `https://chat.squaretime.de/${urlPath}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            message: userMessage,
            sessionId: !sessionId ? await getSessionId() : sessionId,
          }),
          signal: abortController.signal,
        }
      );
      appendMessage("bot", response.data.message, true);
      stopTypingIndicator(); // Stop the typing indicator
    } catch (error) {
      const stackFrames = await StackTrace.fromError(error);
      const errorData = {
        message: `Chat call failed: ${error.message}; response: ${response} SessionId: ${sessionId}`,
        stacks: stackFrames,
        datestamp: format(new Date(), "yyyy-MM-dd HH:mm"),
        userAgent: navigator.userAgent,
      };
      await transmitError(errorData).catch(console.log);

      if (error.name !== "AbortError") {
        console.error("Error sending message:", error);
        appendMessage("bot", "Sorry, there was an issue sending your message. Please try again.");
        stopTypingIndicator(); // Stop the typing indicator
      }
    }
  };

  const downloadChat = async () => {
    const chatContent = messages
      .map(
        (message) => `[${message.sender} - ${message.timestamp.toLocaleTimeString()}]: ${message.messageContent}`
      )
      .join("\n");

    const blob = new Blob([chatContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "chat-history.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    messages,
    appendMessage,
    sendMessage,
    downloadChat,
    typingIndicator,
    sendInitialMessage,
    sessionId,
  };
};

export default useChatMessages;